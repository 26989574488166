'use strict';

var focusHelper = require('base/components/focus');
var formHelpers = require('base/checkout/formErrors');
var reloadGallery = require('./detail').showMainGalleryMobile;
var openZoomDesktop = require('./detail').openZoomDesktop;
var createCarouselDesktop = require('./detail').createCarouselDesktop;
var showRecommendations = require('./detail').showRecommendations;

/**
 * Retrieves the relevant pid value
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - value to be used when adding product to cart
 */
function getPidValue($el) {
    var pid;

    if ($('#quickViewModal').hasClass('show') && !$('.product-set').length) {
        pid = $($el).closest('.modal-content').find('.product-quickview').data('pid');
    } else if ($($el).hasClass('add-to-cart-no-checkout')) {
        pid = $($el).data('pid');
    } else if ($('.product-set-detail').length || $('.product-set').length) {
        pid = $($el).closest('.product-detail').find('.product-id').text();
    } else {
        pid = $('.product-detail:not(".bundle-item")').data('pid');
    }

    return pid;
}

/**
 * Retrieve contextual quantity selector
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {jquery} - quantity selector DOM container
 */
function getQuantitySelector($el) {
    var quantitySelected;
    if ($el && $('.set-items').length) {
        quantitySelected = $($el).closest('.product-detail').find('.quantity-select');
    } else if ($el && $('.product-bundle').length) {
        var quantitySelectedModal = $($el).closest('.modal-footer').find('.quantity-select');
        var quantitySelectedPDP = $($el).closest('.bundle-footer').find('.quantity-select');
        if (quantitySelectedModal.val() === undefined) {
            quantitySelected = quantitySelectedPDP;
        } else {
            quantitySelected = quantitySelectedModal;
        }
    } else {
        quantitySelected = $('.quantity-select');
    }
    return quantitySelected;
}

/**
 * Retrieves the value associated with the Quantity pull-down menu
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {string} - value found in the quantity input
 */
function getQuantitySelected($el) {
    return getQuantitySelector($el).val();
}

/**
 * Change add-to-cart-size-selector button to add-to-cart button.
 */
function changeAddToCartSizeSelectorButton() {
    if ($('button.add-to-cart-size-selector').length) {
        var addToCartSizeSelectorElement = $('button.add-to-cart-size-selector');
        addToCartSizeSelectorElement.addClass('add-to-cart').removeClass('add-to-cart-size-selector');
        addToCartSizeSelectorElement.attr('data-target', '#chooseBonusProductModal');
    }
}

/**
 * Change add-to-cart button to add-to-cart-size-selector button.
 */
function changeAddToCartButton() {
    if ($('button.add-to-cart').length && !$('.js-product-list-size').hasClass('selected')) {
        var addToCartElement = $('button.add-to-cart');
        addToCartElement.addClass('add-to-cart-size-selector').removeClass('add-to-cart');
        addToCartElement.attr('data-target', '#sizeSelectorModal');
        addToCartElement.removeAttr('disabled');
        addToCartElement.removeProp('disabled');
    }
}

/**
 * Process the attribute values for an attribute that has image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 * @param {Object} msgs - object containing resource messages
 * @param {Boolean} updateUrlSwatch - true if you want update the data-url attribute
 */
function processSwatchValues(attr, $productContainer, msgs, updateUrlSwatch) {
    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer.find('[data-attr="' + attr.id + '"] [data-attr-value="' +
            attrValue.value + '"]');
        var $swatchButton = $attrValue.parent();
        var label = $productContainer.find('.' + attr.id + '.non-input-label');

        if (attrValue.selected) {
            if (label && label.length > 0) {
                label.empty().append(attr.displayName + ' : ' + attrValue.displayValue);
            }
            $attrValue.addClass('selected');
            $attrValue.siblings('.selected-assistive-text').text(msgs.assistiveSelectedText);
        } else {
            $attrValue.removeClass('selected');
            $attrValue.siblings('.selected-assistive-text').empty();
        }

        if (updateUrlSwatch) {
            if (attrValue.url) {
                $swatchButton.attr('data-url', attrValue.url);
            } else {
                $swatchButton.removeAttr('data-url');
            }
        }

        // Disable if not selectable
        $attrValue.removeClass('selectable unselectable');

        if (updateUrlSwatch) {
            $attrValue.addClass(attrValue.selectable ? 'selectable' : 'unselectable');
        } else {
            $attrValue.addClass('selectable');
        }
    });
}

/**
 * Recorremos los diferentes selectors, ya que a cada uno hay que añadirle o quitarle diferentes *clases.
 * @param {Array} selectors diferentes selectores de talla
 */
function addClassesDependsOnSelectorType(selectors, isAlertSizeNotificationEnabled) {
    selectors.each(function () {
        var item = $(this)[0];
        if (item.nodeName == 'LI' && isAlertSizeNotificationEnabled) {
            $(this).addClass('unselectable');
        } else if (item.nodeName == 'LI' && !isAlertSizeNotificationEnabled) {
            $(this).append('<div class="diagonal"></div>');
            $(this).addClass('unselectable');
        } else {
            item.setAttribute('disabled', '');
        }
    });
}

function removeIconSizeNotificationFromModal() {
    $('li.js-product-list-size-modal.swatch-rectangle.disabled.unselectable').each(function () {
        if ($(this).find('.email-size-available').length > 0) {
            $(this).find('.email-size-available').remove();
        }
    });
}

function addDiagoanlToModal() {
    $('li.js-product-list-size-modal.swatch-rectangle.disabled.unselectable').each(function () {
        $(this).first().prepend('<div class="diagonal"></div>');
    });
}

function showEmailIconOrDiagonal(params) {
    var $attrValue = params && params.$attrValue;
    var attrValue = params && params.attrValue;
    var attrSelected = params && params.attrSelected;
    var isOutlet = params && params.attrValue.isOutlet;
    var isAlertSizeNotificationEnabled = params && params.attrValue.isAlertSizeNotificationEnabled;

    if (!attrValue.selectable) {
        $attrValue.addClass('disabled');
        if (isAlertSizeNotificationEnabled && !isOutlet) {
            $attrValue.prepend('<div class="icon email-size-available"></div>');
        } else if (isAlertSizeNotificationEnabled && isOutlet) {
            var hasDiagonal = false;
            for (var i = 0; i < $attrValue[0].childNodes.length; i++) {
                var childNode = $attrValue[0].childNodes[i];
                if (childNode.nodeName === 'DIV' && childNode.className === 'diagonal') {
                    hasDiagonal = true;
                }
            }

            if (!hasDiagonal) {
                $attrValue.prepend('<div class="diagonal"></div>');
            }
        }
        addClassesDependsOnSelectorType($attrValue, attrValue.isAlertSizeNotificationEnabled);
    } else {
        $attrValue.removeClass('disabled');
        $attrValue.removeAttr('disabled');
        $attrValue.removeClass('unselectable');
        $attrValue.find('.diagonal').remove();
        if (isAlertSizeNotificationEnabled && !isOutlet) {
            $attrValue.find('.icon').remove();
        }
    }
    if (attrValue.selected) {
        $attrValue.addClass('selected');
        attrSelected = true;
    }

    return attrSelected;
}

/**
 * Process attribute values associated with an attribute that does not have image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 */

function processNonSwatchValues(attr, $productContainer) {
    var $attr = '[data-attr="' + attr.id + '"]';
    var $defaultOption = $productContainer.find($attr + ' .select-' + attr.id + ' option:first');
    $defaultOption.attr('value', attr.resetUrl);
    var attrSelected = false;
    $('.icon.email-size-available').remove();
    $('.icon.email-size-available-white').remove();

    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer
            .find($attr + ' [data-attr-value="' + attrValue.value + '"]');
        $attrValue.attr('value', attrValue.url)
            .removeAttr('hidden');

        var params = {
            attrValue : attrValue,
            $attrValue: $attrValue,
            attrSelected : attrSelected
        };

        //  en base a isOutlet y isAlertSizeNotificationEnabled se mostrar o la diagonal para el tachado o el icono de email para la alerta por tallas.
        attrSelected = showEmailIconOrDiagonal(params);

        // Se añade la información referente a los nuevos atributos añadios para el aviso de stock por tallas.
        $attrValue.attr('data-attr-value', attrValue.displayValue);
        $attrValue.attr('data-attr-ean', attrValue.ean);
        $attrValue.attr('data-attr-stock', attrValue.stock);
    });

    removeIconSizeNotificationFromModal();
    addDiagoanlToModal();

    if (!attrSelected && !$('button.add-to-cart-size-selector').length) {
        changeAddToCartButton();
    }
    // Disabled add to cart pdp button.
    var addToCartElement;
    if ($('button.add-to-cart-size-selector').length) {
        addToCartElement = $('button.add-to-cart-size-selector');
    } else if ($('button.add-to-cart').length) {
        addToCartElement = $('button.add-to-cart');
    }
    if (addToCartElement) {
        if (attr.values.length === 0) {
            addToCartElement.prop('disabled', true);
            addToCartElement.attr('disabled', true);
        } else {
            addToCartElement.removeAttr('disabled');
            addToCartElement.removeProp('disabled');
        }
    }
}

/**
 * Routes the handling of attribute processing depending on whether the attribute has image
 *     swatches or not
 *
 * @param {Object} attrs - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {jQuery} $productContainer - DOM element for a given product
 * @param {Object} msgs - object containing resource messages
 * @param {Boolean} updateUrlSwatch - true if you want update the data-url attribute
 */
function updateAttrs(attrs, $productContainer, msgs, updateUrlSwatch) {
    // Currently, the only attribute type that has image swatches is Color.
    var attrsWithSwatches = ['color'];

    attrs.forEach(function (attr) {
        if (attrsWithSwatches.indexOf(attr.id) > -1) {
            processSwatchValues(attr, $productContainer, msgs, updateUrlSwatch);
        } else {
            processNonSwatchValues(attr, $productContainer);
        }
    });
}

/**
 * Updates the availability status in the Product Detail Page
 *
 * @param {Object} response - Ajax response object after an
 *                            attribute value has been [de]selected
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function updateAvailability(response, $productContainer) {
    var availabilityValue = '';
    var availabilityMessages = response.product.availability.messages;
    if (!response.product.readyToOrder) {
        availabilityValue = '<li><div>' + response.resources.info_selectforstock + '</div></li>';
    } else {
        availabilityMessages.forEach(function (message) {
            availabilityValue += '<li><div>' + message + '</div></li>';
        });
    }

    $($productContainer).trigger('product:updateAvailability', {
        product: response.product,
        $productContainer: $productContainer,
        message: availabilityValue,
        resources: response.resources
    });
}

/**
 * Generates html for product attributes section
 *
 * @param {array} attributes - list of attributes
 * @return {string} - Compiled HTML
 */
function getAttributesHtml(attributes) {
    if (!attributes) {
        return '';
    }

    var html = '';

    attributes.forEach(function (attributeGroup) {
        if (attributeGroup.ID === 'mainAttributes') {
            attributeGroup.attributes.forEach(function (attribute) {
                html += '<div class="attribute-values">' + attribute.label + ': '
                    + attribute.value + '</div>';
            });
        }
    });

    return html;
}

/**
 * Updates DOM using post-option selection Ajax response
 *
 * @param {OptionSelectionResponse} optionsHtml - Ajax response optionsHtml from selecting a product option
 * @param {jQuery} $productContainer - DOM element for current product
 */
function updateOptions(optionsHtml, $productContainer) {
    // Update options
    $productContainer.find('.product-options').empty().html(optionsHtml);
}

/**
 * Update the icons.
 * @param {Object} product - The product.
 */
function updateIcons(product) {
    var ribbonIcons = $('#nameProductContainer .productIcons');
    if (ribbonIcons) {
        ribbonIcons.empty();
    }
    if (product.icons) {
        var template = '';
        if (product.priceInfo) {
            template += '<i class="icon-price--' + product.priceInfo + '"></i>';
        }
        if (product.promotions != null) {
            for (var i = 0; i < product.promotions.length; i++) {
                var promotion = product.promotions[i];
                if (promotion.image != null) {
                    template += '<img src="' + promotion.image.absURL + '" alt="' + promotion.details + '" />';
                }
            }
        }
        if (product.ribbons != null) {
            for (var j = 0; j < product.ribbons.length; j++) {
                var ribbon = product.ribbons[j];
                template += '<img src="' + ribbon + '" alt="' + ribbon + '" />';
            }
        }
        ribbonIcons.html(template);
    }
}

/** Actualiza las imagenes del modelo color seleccionado para el zoom Desktop */
// function createZoomDesktop(images) {
//     var container = $('#zoom #image-container');
//     container.empty();
//     $.each(images, function (key, dataImage) {
//         var url = dataImage[0];
//         var srcset = dataImage[1];
//         var title = dataImage[2];
//         var alt = dataImage[2];
//         if (!srcset.includes('video')) {
//             var preffix = url.substring(url.lastIndexOf('/'), url.length).split('-')[0].substring(1);
//             container.append('<img loading="lazy" class="zoom-slide mediaBox-' + preffix + '" src="' + url + '" alt="' + alt + 'large image number" title="' + title + '"/>');
//         }
//     });
//     openZoomDesktop();
//     createCarouselDesktop();
// }

function createZoomDesktop(images) {
    var container = $('#zoom #image-container');
    container.empty().css({
        position: 'relative',
        overflow: 'hidden'
    });

    $.each(images, function (key, dataImage) {
        var url = dataImage[0];
        var type = dataImage[1];
        var title = dataImage[2];
        var alt = dataImage[3];
        var srcset = dataImage[4];

        if (type !== 'video') {
            var preffix = url.substring(url.lastIndexOf('/'), url.length).split('-')[0].substring(1);
            container.append(
                '<picture class="zoom-slide mediaBox-' + preffix + '" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">' +
                '<source srcset="' + srcset + '" sizes="100vw">' +
                '<img loading="lazy" src="' + url + '" alt="' + alt + '" title="' + title + '" style="width: 100%; height: 100%; object-fit: contain;"/>' +
                '</picture>'
            );
        }
    });

    // Reset zoom
    container.trigger('zoom.destroy');

    openZoomDesktop();
    createCarouselDesktop();
}

/**
 * Actualiza y renderiza las imagenes y video
 * @param {*} media
 * */
function createGallery(media, poster) {
    if (media.length > 0) {
        $('#galleryBox').empty();
        $('#galleryBox').removeClass('slick-initialized slick-slider slick-dotted');
        var videoKey = 0;
        $.each(media, function (key, dataImage) {
            var preffix;
            var url = dataImage[0];
            var type = dataImage[1];
            var title = dataImage[2];
            var alt = dataImage[3];
            var srcset = dataImage[4];

            if (type.includes('video')) {
                preffix = 'video';
            } else {
                preffix = url.substring(url.lastIndexOf('/'), url.length).split('-')[0].substring(1);
            }

            if (type.includes('video')) {
                $('#galleryBox').append('<div class="mediaBox-' + preffix + videoKey + '" tabindex="' + key + '" aria-hidden="false" data-src="' + url + '"><video autoplay muted loop playsinline poster="' + poster + '"><source src="' + url + '" type="video/mp4"></video></div>');
                videoKey++;
            } else {
                $('#galleryBox').append(
                    '<div class="mediaBox-' + preffix + '">' +
                    '<a title="' + title + '" href="' + url + '">' +
                    '<picture>' +
                    '<source srcset="' + srcset + '" sizes="(min-width: 2900px) calc(2900px / 3), (min-width: 2592px) calc(2592px / 3), (min-width: 2150px) calc(2150px / 3), (min-width: 1950px) calc(1950px / 3), (min-width: 1850px) calc(1850px / 3), (min-width: 1650px) calc(1650px / 3), (min-width: 1450px) calc(1450px / 3), (min-width: 1250px) calc(1250px / 3), (min-width: 1050px) calc(1050px / 3), (min-width: 900px) calc(900px / 3), (min-width: 764px) 764px, (min-width: 500px) 500px, (min-width: 450px) 450px, 100vw">' +
                    '<img loading="lazy" src="' + url + '" class="d-block img-fluid" alt="' + alt + '" title="' + title + '">' +
                    '</picture>' +
                    '</a>' +
                    '</div>'
                );
            }
        });
    }
}

/** TODO FBM: custom function to add imgSmall
 * Dynamically creates Bootstrap carousel from response containing images
 * @param {Object[]} imgs - Array of large product images,along with related information
 * @param {Object[]} imgsSmall - Array of small product images,along with related information
 * @param {Object[]} primaryVideos - Array of videos
 * @param {Object[]} primaryImageVideos - Array of small product images videos
 * @param {String} productName
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function createCarousel(imgs, imgsSmall, $productContainer, primaryVideos, primaryImageVideos, productName) {
    var carousel = $productContainer.find('.carousel');
    $(carousel).carousel('dispose');
    var carouselId = $(carousel).attr('id');
    $(carousel).empty().append('<div class="carousel-inner" role="listbox"><a class="carousel-control-prev" href="#' + carouselId + '" role="button" data-slide="prev"><span class="carousel-icon icon-prev" aria-hidden="true"></span><span class="sr-only">' + $(carousel).data('prev') + '</span></a><a class="carousel-control-next" href="#' + carouselId + '" role="button" data-slide="next"><span class="carousel-icon icon-next" aria-hidden="true"></span><span class="sr-only">' + $(carousel).data('next') + '</span></a></div><ol class="carousel-indicators"></ol>');
    for (var i = 0; i < imgs.length; i++) {
        $('<div class="carousel-item"><img src="' + imgs[i].url + '" class="d-block img-fluid" alt="' + imgs[i].alt + ' image number ' + parseInt(imgs[i].index, 10) + '" title="' + imgs[i].title + '" itemprop="image" /></div>').appendTo($(carousel).find('.carousel-inner'));
    }
    // Add videos
    for (var k = 0; k < (primaryVideos && primaryVideos.length); k++) {
        $('<div class="carousel-item video" aria-hidden="false"><video autoplay muted loop controls poster=""><source src="' + primaryVideos[k] + '" type="video/mp4"></video><div class="embed-container"></div>').appendTo($(carousel).find('.carousel-inner'));
    }
    $($(carousel).find('.carousel-item')).first().addClass('active');
    for (var j = 0; j < imgsSmall.length; j++) {
        $('<li tabindex="0" data-target="#' + carouselId + '" data-slide-to="' + j + '" class=""><img src="' + imgsSmall[j].url + '" alt="' + imgsSmall[j].alt + ' image number ' + parseInt(imgsSmall[j].index, 10) + '" title="' + imgsSmall[j].title + '" itemprop="image" /></li>').appendTo($(carousel).find('.carousel-indicators'));
    }
    // Add images videos
    for (var l = 0; l < (primaryImageVideos && primaryImageVideos.length); l++) {
        $('<li tabindex="0" data-target="#' + carouselId + '" data-slide-to="' + parseInt(l + imgsSmall.length, 10) + '" class=""><img src="' + primaryImageVideos[l] + '" alt="' + productName + ' image number ' + parseInt(l + imgsSmall.length, 10) + '" itemprop="imageVideo" /></li>').appendTo($(carousel).find('.carousel-indicators'));
    }
    $($(carousel).find('.carousel-indicators > li')).first().addClass('active');
    if (imgs.length === 1 && primaryVideos && primaryVideos.length === 0) {
        $($(carousel).find('.carousel-indicators, a[class^="carousel-control-"]')).detach();
    }
    $(carousel).carousel();
}


function changeImageModal(imageSmall) {
    var alt = imageSmall.alt.trim();
    var url = imageSmall.url;
    var item = $('#sizeSelectorModal .imageSizeSelectorModal img');
    item.attr('src', url).attr('alt', alt);
}
/** TODO FBM: custom function to add imgSmall in call to createCarousel
 * Parses JSON from Ajax call made whenever an attribute value is [de]selected
 * @param {Object} response - response from Ajax call
 * @param {Object} response.product - Product object
 * @param {string} response.product.id - Product ID
 * @param {Object[]} response.product.variationAttributes - Product attributes
 * @param {Object[]} response.product.images - Product images
 * @param {boolean} response.product.hasRequiredAttrsSelected - Flag as to whether all required
 *     attributes have been selected.  Used partially to
 *     determine whether the Add to Cart button can be enabled
 * @param {jQuery} $productContainer - DOM element for a given product.
 * @param {Boolean} updateUrlSwatch - true if you want update the data-url attribute
 */
function handleVariantResponse(response, $productContainer, updateUrlSwatch) {
    var isChoiceOfBonusProducts =
        $productContainer.parents('.choose-bonus-product-dialog').length > 0;
    var isVaraint;
    if (response.product.variationAttributes) {
        updateAttrs(response.product.variationAttributes, $productContainer, response.resources, updateUrlSwatch);
        isVaraint = response.product.productType === 'variant';
        if (isChoiceOfBonusProducts && isVaraint) {
            $productContainer.parent('.bonus-product-item')
                .data('pid', response.product.id);

            $productContainer.parent('.bonus-product-item')
                .data('ready-to-order', response.product.readyToOrder);
        }
    }

    // Update primary images
    var allGallery = response.mediaFiles;
    var primaryImageUrls = response.product.images.large;
    var color = response.colorSelected;
    var currentImageColorURL = $('#galleryBox div[class^="mediaBox"]:first-child img').attr('src');
    var search = '_' + color.toUpperCase() + '_';
    if (!$('#quickViewModal').hasClass('show')) {
        if (currentImageColorURL && !currentImageColorURL.includes(search)) {
            $.spinner().start();
            createGallery(allGallery, response.videoPoster);
            changeImageModal(response.product.images.small[0]);
            createZoomDesktop(allGallery);
            $.spinner().stop();
        }
    } else {
        var primaryImageSmallUrls = response.product.images.small;
        var primaryImageVideos = response.product.imagesVideos;
        var productName = response.product.productName;
        createCarousel(primaryImageUrls, primaryImageSmallUrls, $productContainer, null, primaryImageVideos, productName);
    }


    // Update pricing
    if (!isChoiceOfBonusProducts) {
        var $priceSelector = $('.prices .price', $productContainer).length
            ? $('.prices .price', $productContainer)
            : $('.prices .price');
        $priceSelector.replaceWith(response.product.price.html);
    }

    // Empty promotions before calculation if it has
    $productContainer.find('.promotions').empty();

    // Update promotions
    if (Array.isArray(response.product.promotions) && response.product.promotions.length > 0) {
        response.product.promotions.forEach(function (promotion, index) { // eslint-disable-line no-unused-vars
            if (promotion.calloutMsg !== '') {
                $productContainer.find('.promotions').empty().html(promotion.calloutMsg);
            }
        });
    } else {
        $productContainer.find('.promotions').empty().html(response.product.promotionsHtml);
    }

    updateAvailability(response, $productContainer);

    if (isChoiceOfBonusProducts) {
        var $selectButton = $productContainer.find('.select-bonus-product');
        $selectButton.trigger('bonusproduct:updateSelectButton', {
            product: response.product, $productContainer: $productContainer
        });
    } else {
        // Enable "Add to Cart" button if all required attributes have been selected
        $('button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global').trigger('product:updateAddToCart', {
            product: response.product, $productContainer: $productContainer
        }).trigger('product:statusUpdate', response.product);
    }

    // Update attributes
    $productContainer.find('.main-attributes').empty()
        .html(getAttributesHtml(response.product.attributes));


    // Update icons.
    updateIcons(response.product);

    if (!$('#quickViewModal').hasClass('show')) {
        reloadGallery();

        if (currentImageColorURL && !currentImageColorURL.includes(search)) {
            var recommendationOther = response.productRecomendationsOther && response.productRecomendationsOther.trim();
            var recommendationCrossSelling = response.productRecomendationsCrossSelling && response.productRecomendationsCrossSelling.trim();
            var currentCarousel = $('#recommendationsContainer');
            currentCarousel.html('');

            // Mostramos los productos recomendados pero si NO estamos en el quick view.
            if (recommendationOther) {
                currentCarousel.append(recommendationOther);
            }

            if (recommendationCrossSelling) {
                currentCarousel.append(recommendationCrossSelling);
            }
            showRecommendations();
        }
    }
}

/**
 * Updates the quantity DOM elements post Ajax call
 * @param {UpdatedQuantity[]} quantities -
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function updateQuantities(quantities, $productContainer) {
    if ($productContainer.parent('.bonus-product-item').length <= 0) {
        var optionsHtml = quantities.map(function (quantity) {
            var selected = quantity.selected ? ' selected ' : '';
            return '<option value="' + quantity.value + '"  data-url="' + quantity.url + '"' +
                selected + '>' + quantity.value + '</option>';
        }).join('');
        getQuantitySelector($productContainer).empty().html(optionsHtml);
    }
}

/**
 * updates the product view when a product attribute is selected or deselected or when
 *         changing quantity
 * @param {string} selectedValueUrl - the Url for the selected variation value
 * @param {jQuery} $productContainer - DOM element for current product
 * @param {Boolean} updateUrlSwatch - true if you want update the data-url attribute
 */
function attributeSelect(selectedValueUrl, $productContainer, updateUrlSwatch) {
    if (selectedValueUrl) {
        $('body').trigger('product:beforeAttributeSelect',
            { url: selectedValueUrl, container: $productContainer });
        $.ajax({
            url: selectedValueUrl,
            method: 'GET',
            success: function (data) {
                handleVariantResponse(data, $productContainer, updateUrlSwatch);
                updateOptions(data.product.optionsHtml, $productContainer);
                updateQuantities(data.product.quantities, $productContainer);
                $('body').trigger('product:afterAttributeSelect',
                    { data: data, container: $productContainer });
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    }
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @return {string} - The provided URL to use when adding a product to the cart
 */
function getAddToCartUrl() {
    return $('.add-to-cart-url').val();
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.choice-of-bonus-product');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @param {Object} data - data object used to fill in dynamic portions of the html
 */
function chooseBonusProducts(data) {
    $('.modal-body').spinner().start();

    if ($('#chooseBonusProductModal').length !== 0) {
        $('#chooseBonusProductModal').remove();
    }
    var bonusUrl;
    if (data.bonusChoiceRuleBased) {
        bonusUrl = data.showProductsUrlRuleBased;
    } else {
        bonusUrl = data.showProductsUrlListBased;
    }

    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="chooseBonusProductModal" tabindex="-1" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog choose-bonus-product-dialog" '
        + 'data-total-qty="' + data.maxBonusItems + '"'
        + 'data-UUID="' + data.uuid + '"'
        + 'data-pliUUID="' + data.pliUUID + '"'
        + 'data-addToCartUrl="' + data.addToCartUrl + '"'
        + 'data-pageStart="0"'
        + 'data-pageSize="' + data.pageSize + '"'
        + 'data-moreURL="' + data.showProductsUrlRuleBased + '"'
        + 'data-bonusChoiceRuleBased="' + data.bonusChoiceRuleBased + '">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <span class="">' + data.labels.selectprods + '</span>'
        + '    <button type="button" class="close pull-right" data-dismiss="modal">'
        + '        <span aria-hidden="true">&times;</span>'
        + '        <span class="sr-only"> </span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
    $('.modal-body').spinner().start();

    $.ajax({
        url: bonusUrl,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
            var parsedHtml = parseHtml(response.renderedTemplate);
            $('#chooseBonusProductModal .modal-body').empty();
            $('#chooseBonusProductModal .enter-message').text(response.enterDialogMessage);
            $('#chooseBonusProductModal .modal-header .close .sr-only').text(response.closeButtonText);
            $('#chooseBonusProductModal .modal-body').html(parsedHtml.body);
            $('#chooseBonusProductModal .modal-footer').html(parsedHtml.footer);
            $('#chooseBonusProductModal').modal('show');
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * (Remove the SFRA alert message)
 * @param {string} response - ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response) {
    $('.minicart').trigger('count:update', response);
}

/**
 * Retrieves the bundle product item ID's for the Controller to replace bundle master product
 * items with their selected variants
 *
 * @return {string[]} - List of selected bundle product item ID's
 */
function getChildProducts() {
    var childProducts = [];
    $('.bundle-item').each(function () {
        childProducts.push({
            pid: $(this).find('.product-id').text(),
            quantity: parseInt($(this).find('label.quantity').data('quantity'), 10)
        });
    });

    return childProducts.length ? JSON.stringify(childProducts) : [];
}

/**
 * Retrieve product options
 *
 * @param {jQuery} $productContainer - DOM element for current product
 * @return {string} - Product options and their selected values
 */
function getOptions($productContainer) {
    var options = $productContainer
        .find('.product-option')
        .map(function () {
            var $elOption = $(this).find('.options-select');
            var urlValue = $elOption.val();
            var selectedValueId = $elOption.find('option[value="' + urlValue + '"]')
                .data('value-id');
            return {
                optionId: $(this).data('option-id'),
                selectedValueId: selectedValueId
            };
        }).toArray();

    return JSON.stringify(options);
}

/**
 * Return an property of an element of a form
 *
 * @param {object} form - DOM form
 * @param {string} element - DOM element (id, class...)
 * @param {string} property - DOM property element
 * @return {string} - Content of a prop
 */
function getPropertyElementForm(form, element, property) {
    return form.find(element).prop(property);
}

/**
 * Return an data of an element of a form
 *
 * @param {object} form - DOM form
 * @param {string} element - DOM element (id, class...)
 * @param {string} data - DOM data element
 * @return {string} - Content of a data
 */
function getDataElementForm(form, element, data) {
    return form.find(element).data(data);
}

/**
 * Return an data of an element of a form
 *
 * @param {object} form - DOM form
 * @param {string} element - DOM element (id, class...)
 * @param {string} data - DOM data element
 * @param {object} error - object with errors
 */
function addErrorForm(form, element, data, error) {
    var inputName = getPropertyElementForm(form, element, 'name');
    error[inputName] = getDataElementForm(form, element, data);
}

/**
 * Function to validate fields form
 *
 * @return {Object} - with error
 */
function getErrorsPersonalizationForm(personalizationForm, name1, name2, date) {
    // Validations
    var MAX_LENGTH_NAME1 = 16;
    var MAX_LENGTH_NAME2 = 16;
    var MAX_LENGTH_DATE = 16;
    var error = {};

    if (!name1 || name1.length > MAX_LENGTH_NAME1) {
        addErrorForm(personalizationForm, '#name1', !name1 ? 'missing-error' : 'range-error', error);
    }
    if (!name2 || name2.length > MAX_LENGTH_NAME2) {
        addErrorForm(personalizationForm, '#name2', !name2 ? 'missing-error' : 'range-error', error);
    }
    if (date.length > MAX_LENGTH_DATE) {
        addErrorForm(personalizationForm, '#date', 'range-error', error);
    }
    return $.isEmptyObject(error) ? null : [error];
}

/**
 * Function to validate personalization form
 *
 * @return {Object} - with form serialize and boolean with isCustomizable
 */
function validatePersonalizationForm() {
    var idPersonalizationForm = '#personalization-form';
    formHelpers.clearPreviousErrors(idPersonalizationForm);
    var personalizationForm = $(idPersonalizationForm);
    var personalizationFormSerialize = personalizationForm.serialize();
    var isCustomizable = false;
    var valid = true;
    var name1 = personalizationForm.find('#name1').val();
    var name2 = personalizationForm.find('#name2').val();
    var date = personalizationForm.find('#date').val();
    if (personalizationForm && personalizationForm.length > 0) {
        isCustomizable = true;
        var errors = getErrorsPersonalizationForm(personalizationForm, name1, name2, date);
        if (errors && errors.length > 0) {
            valid = false;
            personalizationForm.find('#name1').focus();
            errors.forEach(function (error) {
                if (Object.keys(error).length) {
                    // formHelpers.loadFormErrors(idPersonalizationForm, error);
                    $.each(error, function (attr) {
                        $('*[name=' + attr + ']', idPersonalizationForm)
                            .addClass('is-invalid')
                            .siblings('.invalid-feedback')
                            .html(error[attr]);
                    });
                }
            });
        }
    }
    return {
        name1                        : name1,
        name2                        : name2,
        date                         : date,
        valid                        : valid,
        isCustomizable               : isCustomizable,
        personalizationFormSerialize : personalizationFormSerialize
    };
}

/**
 * Makes a call to the server to report the event of adding an item to the cart
 *
 * @param {string | boolean} url - a string representing the end point to hit so that the event can be recorded, or false
 */
function miniCartReportingUrl(url) {
    if (url) {
        $.ajax({
            url: url,
            method: 'GET',
            success: function () {
                // reporting urls hit on the server
            },
            error: function () {
                // no reporting urls hit on the server
            }
        });
    }
}

module.exports = {
    attributeSelect: attributeSelect,
    methods: {
        editBonusProducts: function (data) {
            chooseBonusProducts(data);
        }
    },

    focusChooseBonusProductModal: function () {
        $('body').on('shown.bs.modal', '#chooseBonusProductModal', function () {
            $('#chooseBonusProductModal').siblings().attr('aria-hidden', 'true');
            $('#chooseBonusProductModal .close').focus();
        });
    },

    onClosingChooseBonusProductModal: function () {
        $('body').on('hidden.bs.modal', '#chooseBonusProductModal', function () {
            $('#chooseBonusProductModal').siblings().attr('aria-hidden', 'false');
        });
    },

    trapChooseBonusProductModalFocus: function () {
        $('body').on('keydown', '#chooseBonusProductModal', function (e) {
            var focusParams = {
                event: e,
                containerSelector: '#chooseBonusProductModal',
                firstElementSelector: '.close',
                lastElementSelector: '.add-bonus-products'
            };
            focusHelper.setTabNextFocus(focusParams);
        });
    },

    colorAttribute: function () {
        $(document).on('touchstart click', '[data-attr="color"] button', function (e) {
            e.preventDefault();
            var isSelected = $(this).find('span').hasClass('selected');
            if ($(this).attr('disabled') || isSelected) {
                return;
            }
            var updateUrlSwatch = true;
            var $productContainer;
            $productContainer = $(this).closest('.set-item');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.product-detail');
            }
            $('.js-product-list-size').removeClass('selected');
            $('.js-product-list-size-modal').removeClass('selected');
            attributeSelect($(this).attr('data-url'), $productContainer, updateUrlSwatch);
        });
    },

    selectAttribute: function () {
        $(document).on('change', 'select[class*="select-"], .options-select', function (e) {
            e.preventDefault();
            var $productContainer = $(this).closest('.set-item');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.product-detail');
            }
            var updateUrlSwatch = false;
            attributeSelect(e.currentTarget.value, $productContainer, updateUrlSwatch);
        });
    },

    availability: function () {
        $(document).on('change', '.quantity-select', function (e) {
            e.preventDefault();

            var searchPID = $(this).closest('.product-detail').attr('data-pid');
            var selectorPrefix = '.product-detail[data-pid="' + searchPID + '"]';

            if ($(selectorPrefix + ' .selected-store-with-inventory').is(':visible')) {
                return;
            }

            var $productContainer = $(this).closest('.product-detail');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.modal-content').find('.product-quickview');
            }

            if ($('.bundle-items', $productContainer).length === 0) {
                var updateUrlSwatch = true;
                attributeSelect($(e.currentTarget).find('option:selected').data('url'),
                    $productContainer, updateUrlSwatch);
            }
        });
    },

    addToCart: function () {
        $(document).on('touchstart click', 'button.add-to-cart, button.add-to-cart-global, button.add-to-cart-no-checkout', function () {
            var addToCartUrl;
            var pid;
            var pidsObj;
            var setPids;
            var goToCheckout = $(this).hasClass('add-to-cart');

            var personalizationForm = validatePersonalizationForm();
            if (!personalizationForm.valid) {
                return;
            }

            $('body').trigger('product:beforeAddToCart', this);

            if ($('.set-items').length && $(this).hasClass('add-to-cart-global')) {
                setPids = [];

                $('.product-detail').each(function () {
                    if (!$(this).hasClass('product-set-detail')) {
                        setPids.push({
                            pid: $(this).find('.product-id').text(),
                            qty: $(this).find('.quantity-select').val(),
                            options: getOptions($(this))
                        });
                    }
                });
                pidsObj = JSON.stringify(setPids);
            }

            pid = getPidValue($(this));

            var $productContainer = $(this).closest('.product-detail');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.quick-view-dialog').find('.product-detail');
            }

            addToCartUrl = getAddToCartUrl();

            var form = {
                pid: pid,
                pidsObj: pidsObj,
                childProducts: getChildProducts(),
                quantity: getQuantitySelected($(this)),
                personalizationForm: personalizationForm.personalizationFormSerialize,
                isCustomizable: personalizationForm.isCustomizable,
                name1: personalizationForm.name1,
                name2: personalizationForm.name2,
                date: personalizationForm.date
            };

            if (!$('.bundle-item').length) {
                form.options = getOptions($productContainer);
            }

            var addToCartEvent = $(this).hasClass('add-to-cart-recommendation-btn') ? 'addToCartRecommendation' : 'addToCart';

            $(this).trigger('updateAddToCartFormData', form);
            if (addToCartUrl) {
                $.ajax({
                    url: addToCartUrl,
                    method: 'POST',
                    data: form,
                    success: function (data) {
                        /* Añadir datalayer push, lo ponemos aquí ya que si no selecciona talla, se
                          realiza la petición pero no devuelve un succes, por lo que solo si lo ponemos aquí saltará una vez. */
                        /* eslint-disable no-undef */
                        dataLayer.push({
                            event: addToCartEvent
                        });
                        handlePostCartAdd(data);
                        if (goToCheckout) {
                            $('body').trigger('product:afterAddToCart', data);
                        } else {
                            if ($('#quickViewModal').length) {
                                $('#quickViewModal').modal('hide');
                            }
                            $('body').trigger('cart:update', data);
                        }
                        $.spinner().stop();
                        miniCartReportingUrl(data.reportingURL);
                    },
                    error: function () {
                        $.spinner().stop();
                    }
                });
            }
        });
    },
    selectBonusProduct: function () {
        $(document).on('touchstart click', '.select-bonus-product', function () {
            var $choiceOfBonusProduct = $(this).parents('.choice-of-bonus-product');
            var pid = $(this).data('pid');
            var maxPids = $('.choose-bonus-product-dialog').data('total-qty');
            var submittedQty = parseInt($choiceOfBonusProduct.find('.bonus-quantity-select').val(), 10);
            var totalQty = 0;
            $.each($('#chooseBonusProductModal .selected-bonus-products .selected-pid'), function () {
                totalQty += $(this).data('qty');
            });
            totalQty += submittedQty;
            var optionID = $choiceOfBonusProduct.find('.product-option').data('option-id');
            var valueId = $choiceOfBonusProduct.find('.options-select option:selected').data('valueId');
            if (totalQty <= maxPids) {
                var selectedBonusProductHtml = ''
                + '<div class="selected-pid row" '
                + 'data-pid="' + pid + '"'
                + 'data-qty="' + submittedQty + '"'
                + 'data-optionID="' + (optionID || '') + '"'
                + 'data-option-selected-value="' + (valueId || '') + '"'
                + '>'
                + '<div class="col-sm-11 col-9 bonus-product-name" >'
                + $choiceOfBonusProduct.find('.product-name').html()
                + '</div>'
                + '<div class="col-1"><i class="fa fa-times" aria-hidden="true"></i></div>'
                + '</div>'
                ;
                $('#chooseBonusProductModal .selected-bonus-products').append(selectedBonusProductHtml);
                $('.pre-cart-products').html(totalQty);
                $('.selected-bonus-products .bonus-summary').removeClass('alert-danger');
            } else {
                $('.selected-bonus-products .bonus-summary').addClass('alert-danger');
            }
        });
    },
    removeBonusProduct: function () {
        $(document).on('touchstart click', '.selected-pid', function () {
            $(this).remove();
            var $selected = $('#chooseBonusProductModal .selected-bonus-products .selected-pid');
            var count = 0;
            if ($selected.length) {
                $selected.each(function () {
                    count += parseInt($(this).data('qty'), 10);
                });
            }

            $('.pre-cart-products').html(count);
            $('.selected-bonus-products .bonus-summary').removeClass('alert-danger');
        });
    },
    enableBonusProductSelection: function () {
        $('body').on('bonusproduct:updateSelectButton', function (e, response) {
            $('button.select-bonus-product', response.$productContainer).attr('disabled',
                (!response.product.readyToOrder || !response.product.available || !response.product.availablePrice));
            var pid = response.product.id;
            $('button.select-bonus-product', response.$productContainer).data('pid', pid);
        });
    },
    showMoreBonusProducts: function () {
        $(document).on('touchstart click', '.show-more-bonus-products', function () {
            var url = $(this).data('url');
            $('.modal-content').spinner().start();
            $.ajax({
                url: url,
                method: 'GET',
                success: function (html) {
                    var parsedHtml = parseHtml(html);
                    $('.modal-body').append(parsedHtml.body);
                    $('.show-more-bonus-products:first').remove();
                    $('.modal-content').spinner().stop();
                },
                error: function () {
                    $('.modal-content').spinner().stop();
                }
            });
        });
    },
    addBonusProductsToCart: function () {
        $(document).on('touchstart click', '.add-bonus-products', function () {
            var $readyToOrderBonusProducts = $('.choose-bonus-product-dialog .selected-pid');
            var queryString = '?pids=';
            var url = $('.choose-bonus-product-dialog').data('addtocarturl');
            var pidsObject = {
                bonusProducts: []
            };

            $.each($readyToOrderBonusProducts, function () {
                var qtyOption =
                    parseInt($(this)
                        .data('qty'), 10);

                var option = null;
                if (qtyOption > 0) {
                    if ($(this).data('optionid') && $(this).data('option-selected-value')) {
                        option = {};
                        option.optionId = $(this).data('optionid');
                        option.productId = $(this).data('pid');
                        option.selectedValueId = $(this).data('option-selected-value');
                    }
                    pidsObject.bonusProducts.push({
                        pid: $(this).data('pid'),
                        qty: qtyOption,
                        options: [option]
                    });
                    pidsObject.totalQty = parseInt($('.pre-cart-products').html(), 10);
                }
            });
            queryString += JSON.stringify(pidsObject);
            queryString = queryString + '&uuid=' + $('.choose-bonus-product-dialog').data('uuid');
            queryString = queryString + '&pliuuid=' + $('.choose-bonus-product-dialog').data('pliuuid');
            $.spinner().start();
            $.ajax({
                url: url + queryString,
                method: 'POST',
                success: function (data) {
                    $.spinner().stop();
                    if (data.error) {
                        $('#chooseBonusProductModal').modal('hide');
                        if ($('.add-to-cart-messages').length === 0) {
                            $('body').append('<div class="add-to-cart-messages"></div>');
                        }
                        $('.add-to-cart-messages').append(
                            '<div class="alert alert-danger add-to-basket-alert text-center"'
                            + ' role="alert">'
                            + data.errorMessage + '</div>'
                        );
                        setTimeout(function () {
                            $('.add-to-basket-alert').remove();
                        }, 3000);
                    } else {
                        $('.configure-bonus-product-attributes').html(data);
                        $('.bonus-products-step2').removeClass('hidden-xl-down');
                        $('#chooseBonusProductModal').modal('hide');

                        if ($('.add-to-cart-messages').length === 0) {
                            $('body').append('<div class="add-to-cart-messages"></div>');
                        }
                        $('.minicart-quantity').html(data.totalQty);
                        $('.add-to-cart-messages').append(
                            '<div class="alert alert-success add-to-basket-alert text-center"'
                            + ' role="alert">'
                            + data.msgSuccess + '</div>'
                        );
                        setTimeout(function () {
                            $('.add-to-basket-alert').remove();
                            if ($('.cart-page').length) {
                                location.reload();
                            }
                        }, 1500);
                    }
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        });
    },

    getPidValue: getPidValue,
    getQuantitySelected: getQuantitySelected,
    miniCartReportingUrl: miniCartReportingUrl,
    customSelectSize: function () {
        $('select.select-size').on('change', function () {
            var selectedSize = $('select.select-size option:selected');
            if (selectedSize.hasClass('disabled')) {
                return;
            }
            var size = selectedSize.data('attr-value');
            var value = selectedSize.val();
            $('.product-sizes__selector .js-product-list-size').removeClass('selected');
            $('.product-sizes__selector .js-product-list-size[data-attr-value=' + size + ']').addClass('selected');
            if (size && value) {
                changeAddToCartSizeSelectorButton();
            } else {
                changeAddToCartButton();
            }
        });

        var clearSelectClasses = function () {
            $('.product-sizes__selector .js-product-list-size').removeClass('selected');
            $('.email-size-available-white').addClass('email-size-available');
            $('.email-size-available-white').removeClass('email-size-available-white');
            $('.select-size option').removeAttr('selected');
            $('#notifySizeButton').removeClass('d-none');
            $('#notificationSucces').addClass('d-none');
            $('#notificationFail').addClass('d-none');
            $('#buyButton').removeClass('add-to-cart');
            $('#buyButton').addClass('add-to-cart-size-selector');
            $('#buyButton').attr('data-target', '#sizeSelectorModal');
            $('#buyButtonBlock .prices').removeClass('top');

            // Recoremos las tallas del modal, y también quitamos la seleccion, ya que si se abre el modal, aparecería la última talla seleccionada.
            $('.js-product-list-size-modal').removeClass('selected');
            $('.js-product-list-size-modal').each(function () {
                $(this)[0].childNodes.forEach(function (child) {
                    if (child.nodeName == 'DIV' && child.className.includes('icon email-size-available')) {
                        child.remove();
                    }
                });
            });
        };

        var addSelectClasses = function (sizeObject) {
            var value = sizeObject.data('attr-value');
            sizeObject.addClass('selected');
            $('.select-size option[data-attr-value="' + value + '"]').prop('selected', true);
        };

        $('.js-product-list-size').on('click', function () {
            var sizeObject = $(this);
            var notificationSizeIcon = sizeObject.children('.icon.email-size-available');

            // Mostramos el bloque para introducir el email y avisar cuando entre stock de la talla.
            if (sizeObject.hasClass('selected') && sizeObject.attr('data-attr-stock') == '0.0' && sizeObject.attr('data-attr-value') !== 'U' || (notificationSizeIcon && notificationSizeIcon.length > 0)) {
                clearSelectClasses();
                $('#closeNotify').trigger('click');

                notificationSizeIcon.length > 0 ?
                    notificationSizeIcon.addClass('email-size-available-white') :
                    notificationSizeIcon.removeClass('email-size-available');

                addSelectClasses(sizeObject);

                $('#buyButton').addClass('d-none');
                $('#notifySizeBlock').removeClass('d-none');
                $('#notificationInfo').removeClass('d-none');
                $('.prices.d-lg-none.col-3').addClass('top');
            } else {
                notificationSizeIcon.addClass('email-size-available');
                notificationSizeIcon.removeClass('email-size-available-white');
                $('#buyButton').removeClass('d-none');
                $('#inputEmailBlock').addClass('d-none');
                $('#notifySizeBlock').addClass('d-none');
            }

            var isSelected = sizeObject.hasClass('selected') || sizeObject.hasClass('unselectable') || sizeObject.hasClass('disabled');
            if (isSelected) {
                return;
            }

            clearSelectClasses();
            addSelectClasses(sizeObject);
            $('select.select-size').trigger('change');
        });

        $('#closeNotify').on('click', function () {
            clearSelectClasses();
            $('#notifySizeBlock').addClass('d-none');
            $('#buyButton').removeClass('d-none');
            $('#inputEmailBlock').addClass('d-none');
            $('#closeNotify').addClass('d-none');
        });

        $('#notifySizeButton').on('click', function () {
            $('.prices.d-lg-none.col-3').addClass('top');
            $('#notifySizeButton').addClass('d-none');
            $('#inputEmailBlock').removeClass('d-none');
            $('#inputEmailBlock').addClass('fade-in-block');
            $('#closeNotify').addClass('fade-in-block');
            $('#closeNotify').removeClass('d-none');

            if ($(window).width() < 768) {
                $('#infoProductContainer').get(0).scrollIntoView({ behavior: 'smooth' });
            }
        });

        $('#notifyButtonEmail').on('click', function (e) {
            // Validamos el email introducido por el usuario y ya realizamos llamada al controlador para guardar email en custom object.
            var inputEmail = $('#customerEmail').val();
            inputEmail = inputEmail.trim();
            e.preventDefault();

            if (inputEmail !== '' && /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/.test(inputEmail)) {
                var actionUrl = $('#sizeReminderForm').attr('action');
                var itemSelected =
                    $('.js-product-list-size.swatch-rectangle.unselectable.disabled.selected');

                $('#notify-email-error').css('display', 'none');
                $('#customerEmail').val(inputEmail);
                $('#ean').val(itemSelected.attr('data-attr-ean'));
                $('#size').val(itemSelected.attr('data-attr-value'));

                $.spinner().start();
                var formData = $('#sizeReminderForm');

                $.ajax({
                    url: actionUrl,
                    method: 'POST',
                    data: formData.serialize(),
                    success: function (response) {
                        $.spinner().stop();
                        if (response && !response.succes) {
                            console.error(response.message);
                        }

                        $('#buyButtonBlock .prices').removeClass('top');
                        $('#notificationInfo').addClass('d-none');
                        $('#inputEmailBlock').addClass('d-none');
                        $('#notificationSucces').removeClass('d-none');

                        // Datalayer push para evento Alerta Tallas de GA4.
                        dataLayer.push({
                            event: 'Solicitud Alerta Talla',
                            Categoria : 'Solicitud Alerta Talla',
                            Accion    : 'Solicitud de aviso de disponibiliad de talla por cliente',
                            Etiqueta  : window.location.href
                        });
                    },
                    error: function () {
                        $.spinner().stop();
                    }
                });
            } else {
                var emailError = $('#customerEmail').attr('data-error-email');
                $('#notify-email-error').text(emailError);
                $('#notify-email-error').css('display', 'block');
                return;
            }
        });
    },
    sizeSelectorAddToCartModal: function () {
        $('body').on('click', '.js-product-list-size-modal', function (e) {
            e.preventDefault();
            var sizeObject = $(this);
            var isSelected = sizeObject.hasClass('unselectable') || sizeObject.hasClass('disabled');
            if (isSelected) {
                return;
            }
            var size = $(this).data('attr-value');
            $('.js-product-list-size-modal').removeClass('selected');
            $(this).addClass('selected');
            $('.select-size option').removeAttr('selected');
            $('.select-size option[data-attr-value="' + size + '"]').prop('selected', true);
            // Close modal, select size and add to cart.
            $('.sizeSelectorModalClass').modal('hide');
            if ($('button.add-to-cart-size-selector').length) {
                $('button.add-to-cart-size-selector').blur();
            }
            // Select size.
            $('.js-product-list-size').each(function () {
                if ($(this).data('attr-value') === size) {
                    $(this).fadeIn().delay(500).queue(function () {
                        $(this).click();
                        changeAddToCartSizeSelectorButton();
                        $('button.add-to-cart').fadeIn().delay(500).queue(function () {
                            $(this).focus();
                            $(this).click();
                        });
                    });
                }
            });
        });
    },
    updateIcons: updateIcons,
    updateAvailability: updateAvailability,
    updateOptions: updateOptions,
    updateQuantities: updateQuantities,
    handlePostCartAdd : handlePostCartAdd
};
