// NEW - PIK202006-147 - 210124 - Newsletter Subscription Modal

'use strict';

/* global $ document window */
var newsletterModal = '.newsletterModal';
var formValidation = require('base/components/formValidation');

function isGenderPreferenceSelected() {
    return $('#genderMethod-woman').prop('checked') || $('#genderMethod-man').prop('checked') || $('#genderMethod-both').prop('checked');
}

function openModalContent() {
    $(newsletterModal).css('display', 'block');
    $('<div class="modal-backdrop fade show"></div>').appendTo(document.body);
    $('body').addClass('modal-open');

    $('body').on('click', '.modal-backdrop.show', function (e) {
        e.preventDefault();
        $(newsletterModal).css('display', 'none');
        $('body').removeClass('modal-open');
        $('.modal-backdrop').remove();
        $('body').off('click', '.modal-backdrop.show');
    });
}

module.exports = function () {
    $('body').on('click', '#newsletterModalCloseButton', function (e) {
        e.preventDefault();
        $(newsletterModal).css('display', 'none');
        $('body').removeClass('modal-open');
        $('.modal-backdrop').remove();
        $('body').off('click', '.modal-backdrop.show');
        $('.floating-popup').removeClass('d-none');
    });

    $('body').on('click', '.newsletter-open-modal', function (e) {
        e.preventDefault();
        openModalContent();
        $('.floating-popup').addClass('d-none');
    });

    $('#sf-button').on('click', function () {
        $('#registration-form-email').val('');
        $('label[for="registration-form-email"]').removeClass('no-empty');
        var email = $('#sf-email').val();
        if (email && email.length > 0) {
            $('label[for="registration-form-email"]').addClass('no-empty');
            $('#registration-form-email').val(email);
            $('#registration-form-email').focus();
            /* eslint-disable no-undef */
            dataLayer.push({
                event     : 'Suscripción Newsletter Footer',
                Categoria : 'Suscripción Newsletter Footer',
                Accion    : 'Apertura de pop up con email de cliente',
                Etiqueta  : window.location.href
            });
        } else {
            /* eslint-disable no-undef */
            dataLayer.push({
                event     : 'Suscripción Newsletter Footer',
                Categoria : 'Suscripción Newsletter Footer',
                Accion    : 'Apertura de pop up sin email de cliente',
                Etiqueta  : window.location.href
            });
        }

        // Transfer the value of the checkbox to the modal
        var checkbox = $('#modal-checkbox');
        var modalCheckbox = $('#rgpdTerms');
        modalCheckbox.prop('checked', checkbox.prop('checked'));

        var locale = window.CQuotient.locale;
        var originUrl = window.location.origin;
        var destinyUrl = '/unete.html';
        var fullUrl = originUrl + destinyUrl;
        if (locale === 'en_US' || locale === 'es_US') {
            sessionStorage.setItem('emailValue', email);
            window.location.href = fullUrl;
        } else {
            $('.newsletter-open-modal').trigger('click');
        }
    });
    $('#sf-email').on('keypress', function (event) {
        if (event.which === 13) {
            $('#sf-button').trigger('click');
        }
    });

    $('.floating-popup #closeIconContainer').on('click', function () {
        $('.floating-popup').addClass('d-none');
        var cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)joinToNewslettersFromPopUp\s*=\s*([^;]*).*$)|^.*$/, '$1');

        var deleteCookie = function (name) {
            document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        };
        var setCookie = function (name, value, days) {
            var expires = '';
            if (days) {
                var date = new Date();
                date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
                expires = '; expires=' + date.toUTCString();
            }
            document.cookie = name + '=' + (value || '') + expires + '; path=/;secure=falseSameSite=None';
        };
        cookieValue = '3';
        var cookiePopup = 'joinToNewslettersFromPopUp';
        var cookieDuration = 365;
        deleteCookie(cookiePopup);
        setCookie(cookiePopup, cookieValue, cookieDuration);
    });

    $('.floating-popup #bubbleWrapper').on('click', function (e) {
        e.preventDefault();
        openModalContent();
        $('.floating-popup').addClass('d-none');
    });

    $('body').on('click', '.newsletterSubscriptionButton', function () {
        var rgpdSelected = $('.rgpdTerms').prop('checked');
        if (rgpdSelected) {
            $('.rgpdTerms').removeClass('is-invalid');
        }
        if (!isGenderPreferenceSelected()) {
            $('.genderRadioButtons').addClass('is-invalid');
            $('.gender-preferences-error').addClass('d-block');
        } else {
            $('.genderRadioButtons').removeClass('is-invalid');
            $('.gender-preferences-error').removeClass('d-block');
        }
    });

    $('form.newsletter-registration').submit(function (e) {
        if (isGenderPreferenceSelected()) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            $.ajax({
                url      : url,
                type     : 'post',
                dataType : 'json',
                data     : form.serialize(),
                success  : function (data) {
                    if (!data.success) {
                        if (data.existingCustomer) {
                            $('.newsletter-alert-message').removeClass('d-none');
                        } else {
                            formValidation(form, data);
                            $('.newsletter-alert-message').addClass('d-none');
                        }
                    } else {
                        $('.newsletterModal-step1').addClass('d-none');
                        $('.newsletterModal-step2').removeClass('d-none');
                        $('.modal-body-newsletter').eq(0).addClass('no-margin-top');
                        var pageType = '';
                        /* eslint-disable no-undef */
                        // Recorremos el datalayer para obtener el pageType
                        dataLayer.forEach(function (evento) {
                            if (evento.event === 'pagina') pageType = evento.pageType;
                        });
                        var etiqueta = pageType + '-' + window.location.href;
                        dataLayer.push({
                            event     : 'Suscripción Newsletter',
                            Categoría : 'Suscripción Newsletter',
                            Acción    : 'Suscripción Enviada',
                            Etiqueta  : etiqueta
                        });
                    }
                },
                error: function (err) {
                    console.error(err);
                }
            });
        }
        return false;
    });
};
