'use strict';

/* global $, window, document */

var keyboardAccessibility = require('base/components/keyboardAccessibility');
var inputAnimation = require('core/common/input-animation');
var focusHelper = require('base/components/focus');
var handleADA = require('core/common/handleADA');
var clearSelection = function (element) {
    $(element).closest('.dropdown').children('.dropdown-menu').children('.top-category')
        .detach();
    $(element).closest('.dropdown.show').children('.nav-link').attr('aria-expanded', 'false');
    $(element).closest('.dropdown.show').children('.dropdown-menu').attr('aria-hidden', 'true');
    $(element).closest('.dropdown.show').removeClass('show');
    // Show social icons
    $('.navbar .menu-mobile-links').removeClass('d-none');
    $('.navbar .menu-mobile-links').addClass('d-block');

    $('div.menu-group > ul.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'false');
    $(element).closest('li').detach();
};

function fixHeaderTop() {
    if ($('.header-navigation-content')[0]) {
        if ($(window).scrollTop() >= 30) {
            $('.header-navigation-content').addClass('fixed-header');
            $('.header-navigation-content').css('background-color', '#ffffff');
        } else {
            $('.header-navigation-content').removeClass('fixed-header');
            $('.header-navigation-content').css('background-color', 'transparent');
        }
    }
}

module.exports = function () {
    var isDesktop = function (element) {
        return $(element).parents('.menu-toggleable-left').css('position') !== 'fixed';
    };

    // Viewport height
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    var vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', vh + 'px');

    $(window).resize(function () {
        if ($(window).width() >= 992) {
            $('#sg-navbar-collapse').css('height', '');

            if ($('.header-container-item .navbar-toggler').hasClass('active')) {
                $('.header-container-item .navbar-toggler.active').click();
            }
        }
    });

    var headerBannerStatus = window.sessionStorage.getItem('hide_header_banner');
    $('.header-banner .close').on('click', function () {
        $('.header-banner').addClass('d-none');
        window.sessionStorage.setItem('hide_header_banner', '1');
    });

    if (!headerBannerStatus || headerBannerStatus < 0) {
        $('.header-banner').removeClass('d-none');
    }

    keyboardAccessibility('.main-menu .nav-link, .main-menu .dropdown-link',
        {
            40: function (menuItem) { // down
                if (menuItem.hasClass('nav-item')) { // top level
                    $('.navbar-nav .show').removeClass('show')
                        .children('.dropdown-menu')
                        .removeClass('show');
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    menuItem.find('ul > li > a')
                        .first()
                        .focus();
                } else {
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    if (!(menuItem.next().length > 0)) { // if this is the last menuItem
                        menuItem.parent().parent().find('li > a').first().focus();
                    } else {
                        menuItem.next().children().first().focus();
                    }
                }
            },
            39: function (menuItem) { // right
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.next().children().first().focus();
                } else if (menuItem.hasClass('dropdown')) {
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    $(this).attr('aria-expanded', 'true');
                    menuItem.find('ul > li > a')
                        .first()
                        .focus();
                }
            },
            38: function (menuItem) { // up
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                } else if (menuItem.prev().length === 0) { // first menuItem
                    menuItem.parent().parent().removeClass('show')
                        .children('.nav-link')
                        .attr('aria-expanded', 'false');
                    menuItem.parent().children().last().children() // set the focus to the last menuItem
                        .first()
                        .focus();
                } else {
                    menuItem.prev().children().first().focus();
                }
            },
            37: function (menuItem) { // left
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.prev().children().first().focus();
                } else {
                    menuItem.closest('.show').removeClass('show')
                        .closest('li.show').removeClass('show')
                        .children()
                        .first()
                        .focus()
                        .attr('aria-expanded', 'false');
                }
            },
            27: function (menuItem) { // escape
                var parentMenu = menuItem.hasClass('show')
                    ? menuItem
                    : menuItem.closest('li.show');
                parentMenu.children('.show').removeClass('show');
                parentMenu.removeClass('show').children('.nav-link')
                    .attr('aria-expanded', 'false');
                parentMenu.children().first().focus();
            }
        },
        function () {
            return $(this).parent();
        }
    );

    $('.dropdown:not(.disabled) [data-toggle="dropdown"]:not(.link)')
        .on('click', function (e) {
            if (!isDesktop(this)) {
                $('.modal-background').show();
                // copy parent element into current UL
                var li = $('<li class="dropdown-item top-category" role="button"></li>');
                var link = $(this).clone().removeClass('dropdown-toggle')
                    .removeAttr('data-toggle')
                    .removeAttr('aria-expanded')
                    .attr('aria-haspopup', 'false');
                li.append(link);
                var closeMenu = $('<li class="nav-menu"></li>');
                closeMenu.append($('.close-menu').first().clone());
                $(this).parent().children('.dropdown-menu')
                    .prepend(li)
                    .prepend(closeMenu)
                    .attr('aria-hidden', 'false');
                // copy navigation menu into view
                $(this).parent().addClass('show');
                $(this).attr('aria-expanded', 'true');
                $(link).focus();
                $('div.menu-group > ul.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'true');
                // Hide social icons
                $('.navbar .menu-mobile-links').addClass('d-none');
                $('.navbar .menu-mobile-links').removeClass('d-block');
                e.preventDefault();
            }
        })
        .on('mouseenter', function () {
            if (isDesktop(this)) {
                var eventElement = this;

                if ($('.search.form-search').hasClass('active')) {
                    $('.icon.icon-search:not(.icon-search-mobile)').trigger('click');
                    $('.search.form-search').removeClass('active');
                }

                $('.navbar-nav > li').each(function () {
                    if (!$.contains(this, eventElement)) {
                        $(this).find('.show').each(function () {
                            clearSelection(this);
                        });
                        if ($(this).hasClass('show')) {
                            $(this).removeClass('show');
                            $(this).children('ul.dropdown-menu').removeClass('show');
                            $(this).children('.nav-link').attr('aria-expanded', 'false');
                        }
                    }
                });
                // need to close all the dropdowns that are not direct parent of current dropdown
                $(this).parent().addClass('show');
                $(this).siblings('.dropdown-menu').addClass('show');
                $(this).attr('aria-expanded', 'true');
            }
        })
        .parent()
        .on('mouseleave', function () {
            if (isDesktop(this)) {
                $(this).removeClass('show');
                $(this).children('.dropdown-menu').removeClass('show');
                $(this).children('.nav-link').attr('aria-expanded', 'false');
            }
        });

    $('.navbar>.close-menu>.close-button').on('click', function (e) {
        e.preventDefault();
        $('.menu-toggleable-left').removeClass('in');
        $('.modal-background').hide();

        $('.navbar-toggler').focus();

        $('.main-menu').attr('aria-hidden', 'true');
        $('.main-menu').siblings().attr('aria-hidden', 'false');
        $('header').siblings().attr('aria-hidden', 'false');
    });

    $('.navbar-nav').on('click', '.back', function (e) {
        e.preventDefault();
        clearSelection(this);
    });

    $('.navbar-nav').on('click', '.close-button', function (e) {
        e.preventDefault();
        $('.navbar-nav').find('.top-category').detach();
        $('.navbar-nav').find('.nav-menu').detach();
        $('.navbar-nav').find('.show').removeClass('show');
        $('.menu-toggleable-left').removeClass('in');

        $('.main-menu').siblings().attr('aria-hidden', 'false');
        $('header').siblings().attr('aria-hidden', 'false');

        $('.modal-background').hide();
    });

    $('.navbar-toggler').on('click', function (e) {
        e.preventDefault();

        // Verificamos si el buscador está abierto
        if ($('.icon-search').hasClass('active')) {
            $('.icon-search').removeClass('active');
            $('.form-search').removeClass('active');
            $('.grid-header, .filters-container, .filter-bar, .breadcrumb, .product-breadcrumb').removeClass('search-active');
            $('.search-field').val('');
            $('.header').removeClass('active-background');
        }

        if (!$(this).hasClass('active')) {
            var headerHeight = $('.header-navigation-content')[0].clientHeight;
            var headerBannerHeight = $('.header-banner')[0].clientHeight;
            var windowHeight = window.innerHeight;
            var scrollTop = $(window).scrollTop();

            if ($(window).width() < 992) { // Móvil
                if (scrollTop === 0) {
                    $('#sg-navbar-collapse').css('height', windowHeight - (headerHeight + headerBannerHeight) + 'px');
                    $('#sg-navbar-collapse').css('top', (headerHeight + headerBannerHeight) + 'px');
                    $('#sg-navbar-collapse').css('margin-top', '0');
                } else if ($('.header-navigation-content').eq(0).hasClass('fixed-header')) {
                    $('#sg-navbar-collapse').css('height', windowHeight - headerHeight + 'px');
                    $('#sg-navbar-collapse').css('top', headerHeight + 'px');
                    $('#sg-navbar-collapse').css('margin-top', '0');
                }
            } else { // Desktop
                $('#sg-navbar-collapse').css('height', '100%');

                if (scrollTop === 0) {
                    $('#sg-navbar-collapse').css('top', (headerHeight + headerBannerHeight) + 'px');
                    $('#sg-navbar-collapse').css('margin-top', '0');
                } else if ($('.header-navigation-content').eq(0).hasClass('fixed-header')) {
                    $('#sg-navbar-collapse').css('top', headerHeight + 'px');
                    $('#sg-navbar-collapse').css('margin-top', '0');
                }
            }
        }
        $(this).toggleClass('active');
        $('body').toggleClass('overflow');
        $('.main-menu').toggleClass('in');
        $('.modal-background').show();

        if ($(this).hasClass('active')) {
            if ($('#menu-overlay').length === 0) {
                $('body').append('<div id="menu-overlay"></div>');
            }
            $('#menu-overlay').show();
        } else {
            $('#menu-overlay').hide().remove();
        }

        if ($(window).width() < 992) {
            $('.header').css('background-color', $(this).hasClass('active') ? '#ffffff' : 'transparent');
        }

        $('.header-container').toggleClass('menu-open');

        $('.main-menu').removeClass('d-none');
        $('.main-menu').attr('aria-hidden', 'false');
        $('.main-menu').siblings().attr('aria-hidden', 'true');
        $('header').siblings().attr('aria-hidden', 'true');

        $('.main-menu .nav.navbar-nav .nav-link').first().focus();
    });

    $('.header-action-item').on('click', '.icon-search', function (e) {
        e.preventDefault();

        // Verificamos si el menú está abierto y estamos en desktop (ancho >= 992px)
        if ($('.navbar-toggler').hasClass('active') && $(window).width() >= 992) {
            $('.navbar-toggler').trigger('click');
        }

        var inputText = $('.search-field');
        $(this).toggleClass('active');
        $('.form-search').toggleClass('active');
        $('.grid-header, .filters-container, .filter-bar, .breadcrumb, .product-breadcrumb').toggleClass('search-active');

        // Si la lupa está activa (abriendo el buscador)
        if ($(this).hasClass('active')) {
            if ($(window).width() < 992) { // Solo en móvil
                $('.header').css('background-color', '#ffffff');
            }
            $('.header').addClass('active-background');
        } else if ($(window).scrollTop() === 0) { // Si estamos cerrando la lupa y el scroll está arriba
            if ($(window).width() < 992) { // Si estamos en móvil, verificar si el menú está abierto
                if (!$('.navbar-toggler').hasClass('active')) {
                    $('.header').css('background-color', 'transparent');
                }
            }
            $('.header').removeClass('active-background');
        }

        if (!$(this).hasClass('active')) {
            inputText.val('');
        }
        if ($(this).hasClass('active')) {
            inputText.focus();
        }
    });


    // PIK2021-82 Add Click Event to Enter in action items from header (ADA protocol)
    handleADA.pressEnterInHeaderActionsItems('.icon-search');
    handleADA.pressEnterInHeaderActionsItems('.country-selector');
    handleADA.pressEnterInHeaderActionsItems('.user-message');

    fixHeaderTop();

    $(window).scroll(function () {
        fixHeaderTop();
    });

    $('.mega-menu-content').on('touchstart click', 'button.title.btn', function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        var parents = $(this).parents('.mega-menu-content-item');
        if ($(parents[0]).hasClass('active')) {
            $(parents[0]).removeClass('active');
            $(this).attr('aria-expanded', 'false');
        } else {
            $(parents[0]).addClass('active');
            $(this).attr('aria-expanded', 'true');
        }
    });

    keyboardAccessibility('header .user',
        {
            40: function ($popover) { // down
                if ($popover.children('a').first().is(':focus')) {
                    $popover.next().children().first().focus();
                } else {
                    $popover.children('a').first().focus();
                }
            },
            38: function ($popover) { // up
                if ($popover.children('a').first().is(':focus')) {
                    $(this).focus();
                    $popover.removeClass('show');
                } else {
                    $popover.children('a').first().focus();
                }
            },
            27: function () { // escape
                $('header .user .popover').removeClass('show');
                $('.user').attr('aria-expanded', 'false');
            },
            9: function () { // tab
                $('header .user .popover').removeClass('show');
                $('.user').attr('aria-expanded', 'false');
            }
        },
        function () {
            var $popover = $('.user .popover li.nav-item');
            return $popover;
        }
    );

    $('header .user').on('mouseenter', function () {
        if ($('header .user .popover').length > 0) {
            $('header .user .popover').addClass('show');
            $('.user').attr('aria-expanded', 'true');
            $(this).on('keydown', function (e) {
                var focusParams = {
                    event                     : e,
                    containerSelector         : '.login',
                    firstElementSelector      : '#login-form-email',
                    lastElementSelector       : '#registration-miniLogin',
                    nextToLastElementSelector : '#login-form-email'
                };
                var KEYCODE_TAB = 9;
                var isTabPressed = (focusParams.event.key === 'Tab' || focusParams.event.keyCode === KEYCODE_TAB);

                if (!isTabPressed) {
                    return;
                }
                $('header .user .popover').addClass('show');
                $('.user').attr('aria-expanded', 'true');
                focusHelper.setTabNextFocus(focusParams);
            });
        }
    }).on('mouseleave', function () {
        var parent = $(this).parent();
        if (!parent.find('input').is(':focus')) {
            $('header .user .popover').removeClass('show');
            $('.user').attr('aria-expanded', 'false');
        }
    });

    /** Adding no-empty class to input in order to animate the label */
    inputAnimation.labelAnimation();
};
