/* global $, window, document */
window.jQuery = window.$ = require('jquery');
var processInclude = require('base/util');
var smoothscroll = require('smoothscroll-polyfill');

$(document).ready(function () {
    $('.logo-home').focus();
    processInclude(require('base/components/cookie'));
    processInclude(require('base/components/consentTracking'));
    processInclude(require('base/components/footer'));
    processInclude(require('core/components/miniCart'));
    processInclude(require('base/components/collapsibleItem'));
    processInclude(require('core/components/search'));
    processInclude(require('base/components/clientSideValidation'));
    processInclude(require('base/components/countrySelector'));
    processInclude(require('base/components/toolTip'));
    processInclude(require('core/components/countrySelectorModal'));
    processInclude(require('core/components/geolocationModal'));
    processInclude(require('core/login/login'));
    processInclude(require('core/utilities/navigationUtilities'));
    processInclude(require('./components/newsletterModal'));
    processInclude(require('./components/menu'));
    smoothscroll.polyfill();
});

require('base/thirdParty/bootstrap');
require('core/components/spinner');
require('slick-carousel');
require('core/components/slick-lightbox/slick-lightbox');
