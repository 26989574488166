'use strict';

var base = require('../product/base');
var focusHelper = require('base/components/focus');

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}

/**
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @param {Object} data - AJAX response from the server
 */
function validateBasket(data) {
    if (data.valid.error) {
        if (data.valid.message) {
            var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
                'fade show" role="alert">' +
                '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
                '<span aria-hidden="true">&times;</span>' +
                '</button>' + data.valid.message + '</div>';

            $('.cart-error').append(errorHtml);
        } else {
            $('.cart').empty().append('<div class="row"> ' +
                '<div class="col-12 text-center"> ' +
                '<h1>' + data.resources.emptyCartMsg + '</h1> ' +
                '</div> ' +
                '</div>'
            );
            $('.number-of-items').empty().append(data.resources.numberOfItems);
            $('.minicart-quantity').empty().append(data.numItems);
            $('.minicart-link').attr({
                'aria-label': data.resources.minicartCountOfItems,
                title: data.resources.minicartCountOfItems
            });
            $('.minicart .popover').empty();
            $('.minicart .popover').removeClass('show');
        }

        $('.checkout-btn').addClass('disabled');
    } else {
        $('.checkout-btn').removeClass('disabled');
    }
}

/**
 * re-renders the line totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateLineTotals(data, uuid) {
    for (const item of data.items) {
        if (item.UUID === uuid) {
            $('.total-line-price-' + uuid).empty().append(item.priceTotal.price);
            item.quantity > 1 ?
                $('#alertDuplicate-' + item.EAN).removeClass('d-none') :
                $('#alertDuplicate-' + item.EAN).addClass('d-none');
        }
    }
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {
    $('.number-of-items').empty().append(data.resources.numberOfItems);
    $('.shipping-cost').empty().append(data.totals.totalShippingCost);
    $('.tax-total').empty().append(data.totals.totalTax);
    $('.grand-total-sum').empty().append(data.totals.grandTotal);
    $('.sub-total').empty().append(data.totals.subTotal);
    $('.minicart-quantity').empty().append(data.numItems);
    $('.minicart-link').attr({
        'aria-label': data.resources.minicartCountOfItems,
        title: data.resources.minicartCountOfItems
    });

    if (data.coupon) {
        $('.coupon-discount').removeClass('d-none');
        $('.coupon-code-applied').empty().append(data.coupontext + '(' + data.coupon + ')');
        $('.coupon-code-applied-quantity').empty().append(data.couponValue);
    } else {
        $('.coupon-discount').addClass('d-none');
    }

    if (data.promosValue) {
        $('.promos-discount').removeClass('d-none');
        $('.order-promos-discount-total').empty().append(data.promosValue);
    } else {
        $('.promos-discount').addClass('d-none');
    }

    if (data.totals.orderLevelDiscountTotal.value > 0) {
        $('.order-discount').removeClass('hide-order-discount');
        $('.order-discount-total').empty()
            .append('- ' + data.totals.orderLevelDiscountTotal.formatted);
    } else {
        $('.order-discount').addClass('hide-order-discount');
    }

    if (data.totals.shippingLevelDiscountTotal.value > 0) {
        $('.shipping-discount').removeClass('hide-shipping-discount');
        $('.shipping-discount-total').empty().append('- ' +
            data.totals.shippingLevelDiscountTotal.formatted);
    } else {
        $('.shipping-discount').addClass('hide-shipping-discount');
    }
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
    var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
        'fade show" role="alert">' +
        '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
        '<span aria-hidden="true">&times;</span>' +
        '</button>' + message + '</div>';

    $('.cart-error').append(errorHtml);
}

/**
 * re-renders the approaching discount messages
 * @param {Object} approachingDiscounts - updated approaching discounts for the cart
 */
function updateApproachingDiscounts(approachingDiscounts) {
    var html = '';
    $('.approaching-discounts').empty();
    if (approachingDiscounts.length > 0) {
        approachingDiscounts.forEach(function (item) {
            html += '<div class="single-approaching-discount text-center">'
                + item.discountMsg + '</div>';
        });
    }
    $('.approaching-discounts').append(html);
}

/**
 * Updates the availability of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateAvailability(data, uuid) {
    var lineItem;
    var messages = '';

    for (var i = 0; i < data.items.length; i++) {
        if (data.items[i].UUID === uuid) {
            lineItem = data.items[i];
            break;
        }
    }

    if (lineItem != null) {
        $('.availability-' + lineItem.UUID).empty();

        if (lineItem.availability) {
            if (lineItem.availability.messages) {
                lineItem.availability.messages.forEach(function (message) {
                    messages += '<p class="line-item-attributes">' + message + '</p>';
                });
            }

            if (lineItem.availability.inStockDate) {
                messages += '<p class="line-item-attributes line-item-instock-date">'
                    + lineItem.availability.inStockDate
                    + '</p>';
            }
        }

        $('.availability-' + lineItem.UUID).html(messages);
    }
}

/**
 * Updates details of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateProductDetails(data, uuid) {
    $('.card.product-info.uuid-' + uuid).replaceWith(data.renderedTemplate);
}

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement() {
    if ($('#editProductModal').length !== 0) {
        $('#editProductModal').remove();
    }
    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="editProductModal" tabindex="-1" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog quick-view-dialog">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <button type="button" class="close pull-right" data-dismiss="modal">'
        + '        <span aria-hidden="true">&times;</span>'
        + '        <span class="sr-only"> </span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.product-quickview');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * replaces the content in the modal window for product variation to be edited.
 * @param {string} editProductUrl - url to be used to retrieve a new product model
 */
function fillModalElement(editProductUrl) {
    $('.modal-body').spinner().start();
    $.ajax({
        url: editProductUrl,
        method: 'GET',
        dataType: 'json',
        success: function (data) {
            var parsedHtml = parseHtml(data.renderedTemplate);

            $('#editProductModal .modal-body').empty();
            $('#editProductModal .modal-body').html(parsedHtml.body);
            $('#editProductModal .modal-footer').html(parsedHtml.footer);
            $('#editProductModal .modal-header .close .sr-only').text(data.closeButtonText);
            $('#editProductModal .enter-message').text(data.enterDialogMessage);
            $('#editProductModal').modal('show');
            $('body').trigger('editproductmodal:ready');
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * replace content of modal
 * @param {string} actionUrl - url to be used to remove product
 * @param {string} productID - pid
 * @param {string} productName - product name
 * @param {string} uuid - uuid
 */
function confirmDelete(actionUrl, productID, productName, uuid, productColorToRemove) {
    var $deleteConfirmBtn = $('.cart-delete-confirmation-btn');
    var $productToRemoveSpan = $('.product-to-remove');

    $deleteConfirmBtn.data('pid', productID);
    $deleteConfirmBtn.data('action', actionUrl);
    $deleteConfirmBtn.data('uuid', uuid);

    $productToRemoveSpan.empty().append(productName + ' ' + productColorToRemove);
}


var getColorToRevome = function (buttonClick) {
    var productColorToRemove = '';
    var parent = buttonClick.closest('.card.product-info');
    parent.find('p').each(function (index, element) {
        if (/Colour|Farbe|Color|Couleur|Kleur|Cor/.test(element.className)) {
            productColorToRemove = element.innerText;
            productColorToRemove = productColorToRemove.split(': ');
            productColorToRemove.length > 2 ?
                productColorToRemove = productColorToRemove + ' ' + productColorToRemove.shift() : productColorToRemove = productColorToRemove[1];
            return productColorToRemove;
        }
    });

    if (!productColorToRemove) { // si llega vacío es porque está borrando desde el mini-cart
        parent = buttonClick.closest('.product-line-item-details');
        parent.find('div').each(function (index, element) {
            if (element.className.includes('item-attributes')) {
                productColorToRemove = element.innerText;
                productColorToRemove = productColorToRemove.split('\n');
                productColorToRemove.forEach(function (attr) {
                    if (/Colour|Farbe|Color|Couleur|Kleur|Cor/.test(attr)) {
                        productColorToRemove = attr.split(': ')[1];
                        return productColorToRemove;
                    }
                });
            }
        });
    }
    return productColorToRemove;
};

/**
 * Función para eliminar un producto del carrito
 * @param {*} pid Ean que vamos a eliminar del carrito
 */
function removeComplementaryProduct(pid, uuid) {
    const urlParams = {
        pid: pid,
        uuid: uuid
    };
    const url = $('.remove-product').attr('data-action');
    const removeFromCartUrl = appendToUrl(url, urlParams);

    $.ajax({
        url: removeFromCartUrl,
        type: 'get',
        dataType: 'json',
        success: function (data) {
            if (data.basket.items.length === 0) {
                $('.number-of-items').empty().append(data.basket.resources.numberOfItems);
                $('.minicart-quantity').empty().append(data.basket.numItems);
                $('.minicart-link').attr({
                    'aria-label': data.basket.resources.minicartCountOfItems,
                    title: data.basket.resources.minicartCountOfItems
                });
                $('.minicart .popover').empty();
                $('.minicart .popover').removeClass('show');
                $('body').removeClass('modal-open');
                $('html').removeClass('veiled');
                $('.minicart-empty').removeClass('d-none');
                $('.minicart-item').addClass('d-none');
                window.location.reload();
            } else {
                if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                    for (var i = 0; i < data.toBeDeletedUUIDs.length; i++) {
                        $('.uuid-' + data.toBeDeletedUUIDs[i]).remove();
                    }
                }

                $('.coupons-and-promos').empty().append(data.basket.totals.discountsHtml);
                $('.minicart-empty').addClass('d-none');
                $('.minicart-item').removeClass('d-none');
                updateCartTotals(data.basket);
                updateApproachingDiscounts(data.basket.approachingDiscounts);
                $('body').trigger('setShippingMethodSelection', data.basket);
                validateBasket(data.basket);
                $('body').trigger('cart:onlyUpdate', data);
            }

            $.spinner().stop();
            return;
        },
        error: function (err) {
            if (err.responseJSON.redirectUrl) {
                window.location.href = err.responseJSON.redirectUrl;
            } else {
                createErrorNotification(err.responseJSON.errorMessage);
                $.spinner().stop();
            }
            return;
        }
    });
}

module.exports = function () {
    $('body').on('click', '.remove-product', function (e) {
        e.preventDefault();

        var actionUrl = $(this).data('action');
        var productID = $(this).data('pid');
        var productName = $(this).data('name');
        var uuid = $(this).data('uuid');
        var buttonClick = $(this);

        var productColorToRemove = getColorToRevome(buttonClick);
        confirmDelete(actionUrl, productID, productName, uuid, productColorToRemove);
    });

    $('body').on('afterRemoveFromCart', function (e, data) {
        e.preventDefault();
        var productColorToRemove = getColorToRevome();
        confirmDelete(data.actionUrl, data.productID, data.productName, data.uuid, productColorToRemove);
    });

    $('body').on('click', '.cart-delete-confirmation-btn', function (e) {
        e.preventDefault();

        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var urlParams = {
            pid: productID,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();

        $('body').trigger('cart:beforeUpdate');

        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                if (data.basket.items.length === 0) {
                    $('.number-of-items').empty().append(data.basket.resources.numberOfItems);
                    $('.minicart-quantity').empty().append(data.basket.numItems);
                    $('.minicart-link').attr({
                        'aria-label': data.basket.resources.minicartCountOfItems,
                        title: data.basket.resources.minicartCountOfItems
                    });
                    $('.minicart .popover').empty();
                    $('.minicart .popover').removeClass('show');
                    $('body').removeClass('modal-open');
                    $('html').removeClass('veiled');
                    $('.minicart-empty').removeClass('d-none');
                    $('.minicart-item').addClass('d-none');
                    window.location.reload();
                } else {
                    if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                        for (var i = 0; i < data.toBeDeletedUUIDs.length; i++) {
                            $('.uuid-' + data.toBeDeletedUUIDs[i]).remove();
                        }
                    }
                    $('.uuid-' + uuid).next().remove();
                    $('.uuid-' + uuid).remove();
                    if (!data.basket.hasBonusProduct) {
                        $('.bonus-product').remove();
                    }
                    $('.coupons-and-promos').empty().append(data.basket.totals.discountsHtml);
                    $('.minicart-empty').addClass('d-none');
                    $('.minicart-item').removeClass('d-none');
                    updateCartTotals(data.basket);
                    updateApproachingDiscounts(data.basket.approachingDiscounts);
                    $('body').trigger('setShippingMethodSelection', data.basket);
                    validateBasket(data.basket);

                    // Comprobamos si el elemento eliminado es uno de los productos complementarios, para desmarcarlo.
                    let addComplementaryBlock = true;
                    $('label[id*="labelComplementaryProduct-"]').each(function () {
                        const item = $(this);
                        // Mismo elemento que ha sido eliminado del carrito, lo desmarcamos si está marcado
                        if (item[0].attributes.uuid.value === uuid && item[0].control.checked) {
                            item.trigger('click');
                            addComplementaryBlock = false;
                        }
                    });

                    // Comprobamos si solo queda 1 item en el carrito tras eliminar un item, para obtener los productos recomendados de nuevo.
                    if (data.basket.items.length === 1 && addComplementaryBlock) {
                        const complementaryUrl = $('.card.product-info').attr('data-complementary-url');
                        $.ajax({
                            url: complementaryUrl,
                            type: 'get',
                            dataType: 'json',
                            success: function (dataComplementarySale) {
                                if (dataComplementarySale.success) {
                                    $('#productSetBlock').append(dataComplementarySale.complementarySaleHtml);
                                }
                                $.spinner().stop();
                            },
                            error: function (err) {
                                if (err.responseJSON.redirectUrl) {
                                    window.location.href = err.responseJSON.redirectUrl;
                                }
                            }
                        });
                    }
                }

                $('body').trigger('cart:onlyUpdate', data);
                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('body').on('change', '.quantity-form > .quantity', function () {
        var preSelectQty = $(this).data('pre-select-qty');
        var quantity = $(this).val();
        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');

        var urlParams = {
            pid: productID,
            quantity: quantity,
            uuid: uuid
        };
        url = appendToUrl(url, urlParams);

        $(this).parents('.card').spinner().start();

        $('body').trigger('cart:beforeUpdate');

        $.ajax({
            url: url,
            type: 'get',
            context: this,
            dataType: 'json',
            success: function (data) {
                $('.quantity[data-uuid="' + uuid + '"]').val(quantity);
                $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                data.productID = productID.toString();
                updateLineTotals(data, uuid);
                updateCartTotals(data);
                updateApproachingDiscounts(data.approachingDiscounts);
                updateAvailability(data, uuid);
                validateBasket(data);
                $(this).data('pre-select-qty', quantity);

                $.spinner().stop();
                if ($(this).parents('.product-info').hasClass('bonus-product-line-item') && $('.cart-page').length) {
                    location.reload();
                }
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $(this).val(parseInt(preSelectQty, 10));
                    $.spinner().stop();
                }
            }
        });
    });

    $('.shippingMethods').change(function () {
        var url = $(this).attr('data-actionUrl');
        var urlParams = {
            methodID: $(this).find(':selected').attr('data-shipping-id')
        };
        // url = appendToUrl(url, urlParams);

        $('.totals').spinner().start();
        $('body').trigger('cart:beforeShippingMethodSelected');
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: urlParams,
            success: function (data) {
                if (data.error) {
                    window.location.href = data.redirectUrl;
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                }

                $('body').trigger('cart:shippingMethodSelected', data);
                $.spinner().stop();
            },
            error: function (err) {
                if (err.redirectUrl) {
                    window.location.href = err.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('.promo-code-form').submit(function (e) {
        e.preventDefault();
        $.spinner().start();
        $('.coupon-missing-error').hide();
        $('.coupon-error-message').empty();
        if (!$('.coupon-code-field').val()) {
            $('.promo-code-form .form-control').addClass('is-invalid');
            $('.promo-code-form .form-control').attr('aria-describedby', 'missingCouponCode');
            $('.coupon-missing-error').show();
            $.spinner().stop();
            return false;
        }
        var $form = $('.promo-code-form');
        $('.promo-code-form .form-control').removeClass('is-invalid');
        $('.coupon-error-message').empty();
        $('body').trigger('promotion:beforeUpdate');

        $.ajax({
            url: $form.attr('action'),
            type: 'GET',
            dataType: 'json',
            data: $form.serialize(),
            success: function (data) {
                if (data.error) {
                    $('.promo-code-form .form-control').addClass('is-invalid');
                    $('.promo-code-form .form-control').attr('aria-describedby', 'invalidCouponCode');
                    $('.coupon-error-message').empty().append(data.errorMessage);
                    $('body').trigger('promotion:error', data);
                } else if (data.reloadPayments) {
                    window.location.reload();
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                    $('#remove-promoCode').removeClass('d-none');
                    $('.btn-block.promo-code-btn').addClass('d-none');
                    $('#validCouponCode').removeClass('d-none');
                    $('.coupon-valid-icon').removeClass('d-none');

                    $('body').trigger('promotion:success', data);

                    if (data.renderedOrderProductSummary) {
                        if ($('klarna-placement').length > 0) {
                            var totalGrossPrice = data.totalGrossPrice;
                            document.querySelector('klarna-placement').dataset.purchaseAmount = (totalGrossPrice * 100).toFixed(0);
                            window.KlarnaOnsiteService = window.KlarnaOnsiteService || [];
                            window.KlarnaOnsiteService.push({ eventName: 'refresh-placements' });
                        }
                        var $html = $('<div>').append($.parseHTML(data.renderedOrderProductSummary));
                        var contentOrderSumary = $html.find('.card.order-product-summary').children();
                        if ($('.card.order-product-summary').length > 0) { // are in the checkout
                            $('.card.order-product-summary').empty().append(contentOrderSumary);
                        } else { // are in basket
                            window.location.reload();
                        }
                    }
                }

                $.spinner().stop();
            },
            error: function (err) {
                $('body').trigger('promotion:error', err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.errorMessage);
                    $.spinner().stop();
                }
            }
        });
        return false;
    });

    $('body').on('click', '.cart-page .bonus-product-button', function () {
        $.spinner().start();
        $(this).addClass('launched-modal');
        $.ajax({
            url: $(this).data('url'),
            method: 'GET',
            dataType: 'json',
            success: function (data) {
                base.methods.editBonusProducts(data);
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });

    $('body').on('hidden.bs.modal', '#chooseBonusProductModal', function () {
        $('#chooseBonusProductModal').remove();
        $('.modal-backdrop').remove();
        $('body').removeClass('modal-open');

        if ($('.cart-page').length) {
            $('.launched-modal .btn-outline-primary').trigger('focus');
            $('.launched-modal').removeClass('launched-modal');
        } else {
            $('.product-detail .add-to-cart').focus();
        }
    });

    $('body').on('click', '.cart-page .product-edit .edit, .cart-page .bundle-edit .edit', function (e) {
        e.preventDefault();

        var editProductUrl = $(this).attr('href');
        getModalHtmlElement();
        fillModalElement(editProductUrl);
    });

    $('body').on('shown.bs.modal', '#editProductModal', function () {
        $('#editProductModal').siblings().attr('aria-hidden', 'true');
        $('#editProductModal .close').focus();
    });

    $('body').on('hidden.bs.modal', '#editProductModal', function () {
        $('#editProductModal').siblings().attr('aria-hidden', 'false');
    });

    $('body').on('keydown', '#editProductModal', function (e) {
        var focusParams = {
            event: e,
            containerSelector: '#editProductModal',
            firstElementSelector: '.close',
            lastElementSelector: '.update-cart-product-global',
            nextToLastElementSelector: '.modal-footer .quantity-select'
        };
        focusHelper.setTabNextFocus(focusParams);
    });

    $('body').on('product:updateAddToCart', function (e, response) {
        // update global add to cart (single products, bundles)
        var dialog = $(response.$productContainer)
            .closest('.quick-view-dialog');

        $('.update-cart-product-global', dialog).attr('disabled',
            !$('.global-availability', dialog).data('ready-to-order')
            || !$('.global-availability', dialog).data('available')
        );
    });

    $('body').on('product:updateAvailability', function (e, response) {
        // bundle individual products
        $('.product-availability', response.$productContainer)
            .data('ready-to-order', response.product.readyToOrder)
            .data('available', response.product.available && response.product.availablePrice)
            .find('.availability-msg')
            .empty()
            .html(response.message);


        var dialog = $(response.$productContainer)
            .closest('.quick-view-dialog');

        if ($('.product-availability', dialog).length) {
            // bundle all products
            var allAvailable = $('.product-availability', dialog).toArray()
                .every(function (item) { return $(item).data('available'); });

            var allReady = $('.product-availability', dialog).toArray()
                .every(function (item) { return $(item).data('ready-to-order'); });

            $('.global-availability', dialog)
                .data('ready-to-order', allReady)
                .data('available', allAvailable);

            $('.global-availability .availability-msg', dialog).empty()
                .html(allReady ? response.message : response.resources.info_selectforstock);
        } else {
            // single product
            $('.global-availability', dialog)
                .data('ready-to-order', response.product.readyToOrder)
                .data('available', response.product.available && response.product.availablePrice)
                .find('.availability-msg')
                .empty()
                .html(response.message);
        }
    });

    $('body').on('product:afterAttributeSelect', function (e, response) {
        if ($('.modal.show .product-quickview .bundle-items').length) {
            $('.modal.show').find(response.container).data('pid', response.data.product.id);
            $('.modal.show').find(response.container).find('.product-id').text(response.data.product.id);
        } else {
            $('.modal.show .product-quickview').data('pid', response.data.product.id);
        }
    });

    $('body').on('change', '.quantity-select', function () {
        var selectedQuantity = $(this).val();
        $('.modal.show .update-cart-url').data('selected-quantity', selectedQuantity);
    });

    $('body').on('change', '.options-select', function () {
        var selectedOptionValueId = $(this).children('option:selected').data('value-id');
        $('.modal.show .update-cart-url').data('selected-option', selectedOptionValueId);
    });

    $('body').on('click', '.update-cart-product-global', function (e) {
        e.preventDefault();

        var updateProductUrl = $(this).closest('.cart-and-ipay').find('.update-cart-url').val();
        var selectedQuantity = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('selected-quantity');
        var selectedOptionValueId = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('selected-option');
        var uuid = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('uuid');

        var form = {
            uuid: uuid,
            pid: base.getPidValue($(this)),
            quantity: selectedQuantity,
            selectedOptionValueId: selectedOptionValueId
        };

        $(this).parents('.card').spinner().start();

        $('body').trigger('cart:beforeUpdate');

        if (updateProductUrl) {
            $.ajax({
                url: updateProductUrl,
                type: 'post',
                context: this,
                data: form,
                dataType: 'json',
                success: function (data) {
                    $('#editProductModal').modal('hide');

                    $('.coupons-and-promos').empty().append(data.cartModel.totals.discountsHtml);
                    updateCartTotals(data.cartModel);
                    updateApproachingDiscounts(data.cartModel.approachingDiscounts);
                    updateAvailability(data.cartModel, uuid);
                    updateProductDetails(data, uuid);

                    if (data.uuidToBeDeleted) {
                        $('.uuid-' + data.uuidToBeDeleted).remove();
                    }

                    validateBasket(data.cartModel);
                    $('body').trigger('cart:onlyUpdate', data);
                    $.spinner().stop();
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.responseJSON.errorMessage);
                        $.spinner().stop();
                    }
                }
            });
        }
    });

    $('body').on('click', '.cart_plus', function (e) {
        e.preventDefault();
        var parent = $(this).parent();
        var actualValue = parseInt(parent.find('.quantity').val());
        var maxValue = parseInt(parent.find('.quantity').attr('max'));
        var minValue = parseInt(parent.find('.quantity').attr('min'));
        if (actualValue + 1 > maxValue) {
            return;
        }
        if (actualValue + 1 === maxValue) {
            $(this).attr('disabled', true);
        }
        if (actualValue + 1 > minValue) {
            parent.find('#cart_minus').removeAttr('disabled');
        }
        parent.find('.quantity').val(actualValue + 1);
        parent.find('.quantity').trigger('change');
    });

    $('body').on('click', '.cart_minus', function (e) {
        e.preventDefault();
        var parent = $(this).parent();
        var actualValue = parseInt(parent.find('.quantity').val());
        var maxValue = parseInt(parent.find('.quantity').attr('max'));
        var minValue = parseInt(parent.find('.quantity').attr('min'));
        if (actualValue - 1 < minValue) {
            return;
        }
        if (actualValue - 1 === minValue) {
            $(this).attr('disabled', true);
        }
        if (actualValue - 1 < maxValue) {
            parent.find('#cart_plus').removeAttr('disabled');
        }
        parent.find('.quantity').val(actualValue - 1);
        parent.find('.quantity').trigger('change');
    });

    $('body').on('click', '#remove-promoCode', function (e) {
        e.preventDefault();
        const url = $('#remove-promoCode').attr('data-remove-coupon');

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                updateCartTotals(data);
                updateApproachingDiscounts(data);
                validateBasket(data);

                $('.coupon-code-field').val('');
                $('#remove-promoCode').addClass('d-none');
                $('#validCouponCode').addClass('d-none');
                $('.coupon-valid-icon ').addClass('d-none');
                $('.btn-block.promo-code-btn').removeClass('d-none');
                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                }
                $.spinner().stop();
            }
        });
    });

    /* Añadimos los productos recomendados al carrito, misma llamada de addToCart pero sin reload */
    $('body').on('touchstart click', 'input.add-to-cart-recommendation-btn', function () {
        $('body').trigger('product:beforeAddToCart', this);
        const pid = $(this).attr('data-complementary-ean');
        const addToCartUrl = $(this).attr('data-complementary-method-url');

        const form = {
            pid: pid,
            quantity: '1',
            isCustomizable: 'false'
        };

        $(this).trigger('updateAddToCartFormData', form);

        // Comprobamos si está marcado el producto, para añadirlo o eliminarlo del carrito. Cuando se hace click y llegamos aquí ya está marcado el check, por lo que tenemos que tener en cuenta la negación para añadir el elemento al carrito.
        const isItemInCart = !$(`#labelComplementaryProduct-${pid}`)[0].control.checked;

        $.spinner().start();
        if (addToCartUrl && !isItemInCart) {
            $.ajax({
                url: addToCartUrl,
                method: 'POST',
                data: form,
                success: function (data) {
                    /* Añadir datalayer push, lo ponemos aquí ya que si no selecciona talla, se
                        realiza la petición pero no devuelve un success, por lo que solo si lo ponemos aquí saltará una vez. */
                    /* eslint-disable no-undef */
                    dataLayer.push({
                        event: 'addToCartRecommendation'
                    });

                    $('body').trigger('cart:onlyUpdate', data);

                    // Necesitamos obtener el UUID del producto en el carrito, para si desea borrarlo el cliente, tener su UUID y poder eliminarlo.
                    const newItemUUID = data.cart.items[data.cart.items.length - 1].UUID;
                    $(`#complementaryProduct-${pid}`).attr('uuid', newItemUUID);
                    $(`#labelComplementaryProduct-${pid}`).attr('uuid', newItemUUID);

                    base.handlePostCartAdd(data);
                    updateCartTotals(data.cart);
                    validateBasket(data.cart);
                    $.spinner().stop();
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        } else {
            $('body').trigger('cart:beforeUpdate');
            const uuid = $(this).attr('uuid');
            removeComplementaryProduct(pid, uuid);
            // Añadimos Evento al eliminar cuando se hace click también en el check y quitamos el producto del carrito, para tener un evento en gtm que nos ayude a no enviar eventos duplicados.
            /* eslint-disable no-undef */
            dataLayer.push({
                event: 'removeFromCartRecommendation'
            });
        }
    });


    $(window).on('scroll', function () {
        const totalsElement = $('.totals');
        if (totalsElement.length === 0) return;
        if ((window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) < 769) {
            const footer = $('footer');
            if (footer.length === 0) return;

            const footerHeight = footer[0].clientHeight;
            const footerIsVisible = document.scrollingElement.scrollHeight - document.scrollingElement.scrollTop - footerHeight <= window.innerHeight;

            if (footerIsVisible) {
                totalsElement.eq(0).css({
                    position: 'absolute',
                    bottom: '-' + (totalsElement[0].clientHeight + 25) + 'px',
                    'box-shadow': 'none',
                    margin: '15px 0px'
                });

                const mainContent = $('#maincontent');
                if (mainContent.length > 0) {
                    mainContent.css('margin-bottom', (totalsElement[0].clientHeight + 25) + 'px');
                }
            } else {
                totalsElement.eq(0).css({
                    position: '',
                    bottom: '',
                    'box-shadow': '',
                    margin: '0px'
                });
            }
        } else {
            totalsElement.eq(0).css({
                position: '',
                bottom: '',
                'box-shadow': '',
                margin: '0px'
            });
        }
    });

    base.selectAttribute();
    base.colorAttribute();
    base.removeBonusProduct();
    base.selectBonusProduct();
    base.enableBonusProductSelection();
    base.showMoreBonusProducts();
    base.addBonusProductsToCart();
    base.focusChooseBonusProductModal();
    base.trapChooseBonusProductModalFocus();
    base.onClosingChooseBonusProductModal();
};
